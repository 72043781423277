.last-trace-truck-cell {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > .header {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    > .panel {
      width: 52px;
      display: flex;
      margin-right: 4px;
      margin-top: -4px;
      > .speed {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 0;
      }
      > .status {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        > .ignition {
          font-size: 5px;
          line-height: 6px;
          margin-top: 2px;
          margin-bottom: 0;
        }
        > .unit-meter {
          font-size: 8px;
          line-height: 8px;
          margin-bottom: 0;
        }
      }
    }
    > .location {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      line-height: 12px;
    }
    > .time {
      white-space: nowrap;
      margin-left: 6px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      line-height: 12px;
    }
  }
  > .description {
    width: 100%;
    font-size: 8px;
    line-height: 8px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
