.worker-overview-drawer {
  .ant-drawer-body {
    .worker-overview-drawer-timeline {
      .timeline-filters {
        margin-bottom: 16px;
      }
      .ant-timeline {
        .ant-timeline-item-content {
          span {
            display: block;
            padding-left: 46px;
            &:first-child {
              display: inline;
              padding-left: 0px;
            }
            i {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
