.service-drawer {
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      .ant-drawer-body {
        p {
          font-size: 12px;
          line-height: 16px;
        }
        .ant-timeline {
          margin-left: 8px;

          .ant-timeline-item-content {
            font-size: 12px;
            line-height: 16px;
            > .maintenance-timeline-event-description {
              display: block;
              text-indent: 10%;
              > .sector::before { content: " (" }
              > .sector::after { content: ")" }
              > .procedure {
                display: block;
                text-indent: 20%;
              }
              > .worker {
                display: block;
                text-indent: 20%;
                > .anticon {
                  margin-left: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}
