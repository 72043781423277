.worker-card.ant-card {
  &.status-WORKING {
    color: #2f54eb;
    background: #f0f5ff;
    border-color: #adc6ff;
  }
  > .ant-card-body {
    position: relative;
    padding: 12px 16px;
    width: 100%;

    > .first-name {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      font-size: 20px;
      line-height: 1.4;
      white-space: nowrap;
      overflow: hidden;
    }
    > .last-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 16px;
    }
    > .counter {
      position: absolute;
      top: 12px;
      right: 18px;
      width: 80px;
      text-align: right;
      font-weight: bold;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.85);
      background: #fff;
    }
    > .footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      > .tags {
        .status-tag {
          margin-bottom: 0px;
          margin-right: 8px;
        }
        .sector-tag {
          margin-right: 0px;
        }
      }
      > .actions {
        position: absolute;
        bottom: 12px;
        right: 18px;
        > .anticon {
          margin-left: 8px;
        }
      }
    }
  }
}
