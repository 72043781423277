@import '~antd/dist/antd.less';

@primary-color: #f1755f; // primary color for all components
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 4px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers

body {
  background-color: #ececec;
}

.app-body {
  > .loading-spin {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.data-changed {
  background: rgba(254, 195, 36, 0.05) !important;
  border-color: #eeae01 !important;
  color: #eeae01 !important;
}
