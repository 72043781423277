.service-code-cell {
  cursor: pointer;
  font-size: 12px;
  &.emergency-care {
    background-color: rgba(241,112,86, 0.4);
  }
  .code {
    margin-right: 4px;
  }
  .status-tag {
    display: block;
    line-height: 12px;
    > .ant-tag {
      font-size: 10px;
      line-height: 16px;
      padding: 0 4px;
      margin-right: 0px;
    }
  }
}
