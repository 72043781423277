.card-sector-service {
  .ant-card-body {
    position: relative;
    padding: 12px 16px;
    width: 100%;

    > .rdpr-service-id {
      position: absolute;
      bottom: 12px;
      right: 18px;
    }
    > .vehicle-id {
      white-space: nowrap;
      overflow: hidden;
      .license-plate {
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        font-family: 'Roboto Mono', monospace;
        color: rgba(0, 0, 0, 0.85);
      }
      .vehicle-kind {
        font-size: 12px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.3);
        margin-left: 2px;
      }
    }
    > .counter {
      position: absolute;
      top: 12px;
      right: 18px;
      width: 80px;
      text-align: right;
      font-weight: bold;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.85);
      background: #fff;
    }
    > .details {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      text-align: left;
      > div:nth-child(1) {
        margin-top: 4px;
        margin-bottom: 2px;
        color: rgba(0,0,0,0.3);
        font-size: 12px;
        line-height: 12px;
      }
      > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > div {
          margin-right: 8px;
          i {
            margin-right: 4px;
          }
        }
        > div:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
