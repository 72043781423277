.truck-demand-widget {
  position: relative;
  width: 284px;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    text-align: center;
  }

  .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 12px;
    .counter {
      margin-left: 6px;
      font-size: 12px;
      line-height: 12px;
      color: rgba(0,0,0,0.5)
    }
  }

  .footer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .actions {
      margin-right: 8px;
      > .anticon {
        vertical-align: 0;
        margin-right: 8px;
      }
      > .anticon:last-child {
        margin-right: 0px;
      }
    }
    .note {
      margin-top: 4px;
      font-size: 10px;
      line-height: 10px;
      color: rgba(0,0,0,0.5)
    }
  }
}
