.service-sector-cell {
  .procedures-count {
    font-size: 12px;
    line-height: 12px;
    margin-right: 4px;
    .anticon {
      margin-right: 4px;
    }
  }
  .workers-count {
    font-size: 12px;
    line-height: 12px;
    margin-right: 4px;
    .anticon {
      margin-right: 4px;
    }
  }
  .status-tag {
    display: block;
    line-height: 12px;
    .ant-tag {
      font-size: 10px;
      line-height: 16px;
      padding: 0 4px;
      margin-right: 0px;
    }
  }
}
